<template>
    <div>
        <b-field grouped>
            <b-field label="País" label-position="on-border">
                <b-select :name="'pais_' + alternativeCode" v-model="selectedCountry" @input="loadProvinces()" :required="alternativeCode.length == 0" validation-message="Por favor, seleccione una opción" placeholder="Seleccione una opción">
                    <option v-for="location in locations" :value="location.location_id" :key="location.location_id">
                        {{ location.location_description }}
                    </option>
                </b-select>
            </b-field>
            <b-field label="Provincia" label-position="on-border">
                <b-select :name="'provincia_' + alternativeCode" v-model="selectedProvince" @input="loadCounty()" :required="alternativeCode.length == 0" validation-message="Por favor, seleccione una opción" placeholder="Seleccione una opción">
                    <option v-if="provinces == null" disabled>Seleccione un país</option>
                    <template v-else>
                        <option v-for="province in provinces" :value="province.location_id" :key="province.location_id">
                            {{ province.location_description }}
                        </option>
                    </template>
                </b-select>
            </b-field>
            <b-field label="Cantón" label-position="on-border">
                <b-select :name="'canton_' + alternativeCode" v-model="selectedCounty" @input="loadDistricts()" :required="alternativeCode.length == 0" validation-message="Por favor, seleccione una opción" placeholder="Seleccione una opción">
                    <option v-if="counties == null" disabled>Seleccione una province</option>
                    <template v-else>
                        <option v-for="county in counties" :value="county.location_id" :key="county.location_id">
                            {{ county.location_description }}
                        </option>
                    </template>
                </b-select>
            </b-field>
            <b-field label="Distrito" label-position="on-border">
                <b-select :name="'distrito_' + alternativeCode" v-model="selectedDistrict" @input="loadSpecialCode()" :required="alternativeCode.length == 0" validation-message="Por favor, seleccione una opción" placeholder="Seleccione una opción">
                    <option v-if="districts == null" disabled>Seleccione un cantón</option>
                    <template v-else>
                        <option v-for="district in districts" :value="district.location_id" :key="district.location_id">
                            {{ district.location_description }}
                         
                        </option>
                    </template>
                </b-select>
             
            </b-field>
         




            <b-field label="Fecha estimada de visita" label-position="on-border"  >
                <b-input :name="'special_code_' + alternativeCode"   readonly v-model="specialCode"></b-input>
            </b-field>
 
    <b-field label="Zona" label-position="on-border"  >
                <b-input :name="'special_codes_' + alternativeCode"   readonly v-model="days"></b-input>
            </b-field>
 

  {{    this.loadDays()}}
 



        </b-field>
        <b-field :label="'Direccion entrega ' + alternativeName" label-position="on-border">
            <b-input type="textarea" :name="'direccion_entrega_' + alternativeCode" v-model="ubication"></b-input>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: 'Cedula',
        props: ['locations', 'alternativeName', 'alternativeCode', 'defaults'],
        data() {
            return {
                selectedCountry: null,
                provinces: null,
                selectedProvince: null,
                counties: null,
                selectedCounty: null,
                districts: null,
                selectedDistrict: null,
                specialCode: null,
                 dias: null,
                ubication: '',
            }
        },
        mounted() {
              {{this.loadDays()}}
            this.$nextTick(function ()
            {
                if (this.defaults)
                {
                    this.selectedCountry = this.defaults.id_pais
                    this.loadProvinces()
                    this.selectedProvince = this.defaults.id_provincia
                    this.loadCounty()
                    this.selectedCounty = this.defaults.id_canton
                    this.loadDistricts()
                    this.selectedDistrict = this.defaults.id_distrito
                    this.loadSpecialCode()
                    this.ubication = this.defaults.ubicacion
                    this.loadDays()
                }
            })
        },
        methods: {
            loadProvinces()
            {
                this.provinces = null
                this.counties = null
                this.districts = null
                this.specialCode = null
                this.selectedProvince = null
                this.counties = null
                this.days = null
                this.selectedCounty = null
                this.selectedDistrict = null
                this.provinces = this.locations.find( x => x.location_id == this.selectedCountry).childs
            },
            loadCounty()
            {
                this.districts = null
                this.specialCode = null
                this.selectedDistrict = null
                this.counties = this.provinces.find( x => x.location_id == this.selectedProvince).childs
            },
            loadDistricts()
            {
                this.districts = null
                this.specialCode = null
                this.districts = this.counties.find( x => x.location_id == this.selectedCounty).childs
            },

            loadSpecialCode()
            {
                this.specialCode = this.districts.find( x => x.location_id == this.selectedDistrict).special_code
           
           
    
 this.days = this.districts.find( x => x.location_id == this.selectedDistrict).special_code
    
       axios.get('/app/asm/getzona',{ params: { specialCode: this.specialCode }} )
                   
                        .then( responses => {
                     console.log(responses.data);

this.days=responses.data;


                        }).catch(err => {
                            console.log( "Error: " + err );
                        })




    axios.get('/app/asm/getdata',{ params: { specialCode: this.specialCode }} )
                   
                        .then( response => {
                 
                         this.specialCode =response.data;

                        }).catch(err => {
                            console.log( "Error: " + err );
                        })


   
            },

              loadDays()
            {
           
             




            }
        }
    }
</script>
