<template>
  <div>
    <section>
      <div class="message-header">
        <span>Buscar</span>
      </div>
      <div class="message-body">
        <form @submit.prevent.stop="loadPollsAsync">
          <b-field grouped>
            <b-field label="ID Producto" label-position="on-border" expanded>
              <b-input
                name="id_producto"
                v-model="productID"
                expanded
                icon="boxes"
              ></b-input>
            </b-field>
            <b-field
              label="Fecha de respuesta"
              label-position="on-border"
              expanded
            >
              <b-datepicker
                v-model="pollDate"
                expanded
                name="fecha_respuesta"
                icon="calendar-day"
                :date-formatter="dateFormat"
                :max-date="maxDate"
                :month-names="monthNames"
                :day-names="dayNames"
              ></b-datepicker>
            </b-field>
            <button type="submit" class="button is-primary ml-4">Buscar</button>
          </b-field>
        </form>
      </div>
    </section>
    <section>
      <div class="message-header">
        <span>Resultado de encuestas completadas</span>
        <excel-exporter
          :values="excelData"
          :apicall="excelapi"
        ></excel-exporter>
      </div>
      <div class="message-body">
        <article class="message is-info">
          <div class="message-body">Total de registros: {{ polls.total }}</div>
        </article>
        <b-table
          :data="polls.data"
          :loading="loading"
          paginated
          backend-pagination
          :total="polls.total"
          :per-page="perPage"
          @page-change="onPageChange"
          pagination-position="bottom"
          striped
          class="has-margin-top-10"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Lista"
          aria-current-label="Actual"
          ref="table"
          detailed
          detail-key="token_id"
        >
          <b-table-column v-slot="props" label="ID Producto">
            {{ props.row.id_producto }}
          </b-table-column>
          <b-table-column v-slot="props" label="Número de cédula">
            {{ props.row.numero_cedula }}
          </b-table-column>
          <b-table-column v-slot="props" label="Código de barras">
            {{ props.row.codigo_barra }}
          </b-table-column>
          <b-table-column v-slot="props" label="Fecha de respuesta">
            {{
              new Date(props.row.fecha_respuesta).toLocaleDateString("es-CR")
            }}, {{ new Date(props.row.fecha_respuesta).toLocaleTimeString() }}
          </b-table-column>
          <b-table-column v-slot="props" label="Clalificación total">
            <span
              class="is-size-4 has-text-weight-bold"
              v-bind:data-rating="props.row.calificacion_total"
            >
              {{ props.row.calificacion_total }}%
            </span>
          </b-table-column>
          <template slot="detail" slot-scope="props">
            <table class="table">
              <thead>
                <tr>
                  <th>Pregunta</th>
                  <th>Respuesta</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(details, index) in props.row.detalles_preguntas"
                  :key="index"
                >
                  <th>{{ details.detalle_pregunta }}</th>
                  <td v-bind:data-quality="details.respuesta">
                    {{ details.respuesta }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="has-text-grey has-text-centered">
                <div>No se encontraron resultados</div>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "TableQualityPolls",
  props: ["api", "excelapi"],
  data() {
    const today = new Date();
    return {
      polls: [],
      loading: false,
      perPage: 0,
      currentPage: 1,
      pollDate: undefined,
      productID: undefined,
      dateFormat: (date) => date.toLocaleDateString("es-CR"),
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      dayNames: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
    };
  },
  created() {
    this.perPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);
  },
  mounted() {
    this.loadPollsAsync();
  },
  computed: {
    excelData() {
      let date;

      if (this.pollDate) date = this.dateFormat(this.pollDate);

      return {
        id_producto: this.productID,
        fecha_respuesta: date,
      };
    },
  },
  methods: {
    loadPollsAsync() {
      let date;

      if (this.pollDate) date = this.dateFormat(this.pollDate);

      let request = {
        page: this.currentPage,
        id_producto: this.productID,
        fecha_respuesta: date,
      };

      this.loading = true;

      axios
        .post(this.api, request)
        .then((response) => {
          this.loading = false;
          this.polls = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          alert("Falló la tabla, si sigue pasando contacte con el sysadmin");
        });
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadPollsAsync();
    },
  },
};
</script>
