<template>
  <div>
    <b-field grouped>
      <b-field label="País" label-position="on-border" expanded>
        <b-select
          :name="'pais_' + alternativeCode"
          v-model="selectedCountry"
          @input="loadProvinces()"
          :required="alternativeCode.length == 0"
          validation-message="Por favor, seleccione una opción"
          placeholder="Seleccione una opción"
          expanded
          class="is-fullwidth"
        >
          <option
            v-for="location in locations"
            :value="location.location_id"
            :key="location.location_id"
          >
            {{ location.location_description }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Provincia" label-position="on-border" expanded>
        <b-select
          :name="'provincia_' + alternativeCode"
          v-model="selectedProvince"
          @input="loadCounty()"
          :required="alternativeCode.length == 0"
          validation-message="Por favor, seleccione una opción"
          placeholder="Seleccione una opción"
          expanded
          class="is-fullwidth"
        >
          <option v-if="provinces == null" disabled>Seleccione un país</option>
          <template v-else>
            <option
              v-for="province in provinces"
              :value="province.location_id"
              :key="province.location_id"
            >
              {{ province.location_description }}
            </option>
          </template>
        </b-select>
      </b-field>
      <b-field label="Cantón" label-position="on-border" expanded>
        <b-select
          :name="'canton_' + alternativeCode"
          v-model="selectedCounty"
          @input="loadDistricts()"
          :required="alternativeCode.length == 0"
          validation-message="Por favor, seleccione una opción"
          placeholder="Seleccione una opción"
          expanded
          class="is-fullwidth"
        >
          <option v-if="counties == null" disabled>
            Seleccione una province
          </option>
          <template v-else>
            <option
              v-for="county in counties"
              :value="county.location_id"
              :key="county.location_id"
            >
              {{ county.location_description }}
            </option>
          </template>
        </b-select>
      </b-field>
      <b-field label="Distrito" label-position="on-border" expanded>
        <b-select
          :name="'distrito_' + alternativeCode"
          v-model="selectedDistrict"
          @input="loadSpecialCode()"
          :required="alternativeCode.length == 0"
          validation-message="Por favor, seleccione una opción"
          placeholder="Seleccione una opción"
          expanded
          class="is-fullwidth"
        >
          <option v-if="districts == null" disabled>
            Seleccione un cantón
          </option>
          <template v-else>
            <option
              v-for="district in districts"
              :value="district.location_id"
              :key="district.location_id"
            >
              {{ district.location_description }}
            </option>
          </template>
        </b-select>
      </b-field>
    </b-field>
    <b-field grouped>
      <b-field label="Fecha estimada de visita" label-position="on-border" expanded>
        <b-select
          :name="'special_code_' + alternativeCode"
          expanded
          v-model="selectedDay"
          class="is-fullwidth"
        >
          <option v-if="days.length === 0" disabled>
            Seleccione un distrito
          </option>
          <template v-else>
            <option
              v-for="(day, index) in days"
              :value="day"
              :key="index"
              :selected="index === 0"
            >
              {{ day }}
            </option>
          </template>
        </b-select>
      </b-field>
      <b-field label="Zona" label-position="on-border" expanded>
        <b-input readonly v-model="zona" expanded class="is-fullwidth"></b-input>
      </b-field>
    </b-field>
    <b-field
      :label="'Direccion entrega ' + alternativeName"
      label-position="on-border"
    >
      <b-input
        type="textarea"
        :name="'direccion_entrega_' + alternativeCode"
        v-model="ubication"
      ></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "SelectLocations1",
  props: ["locations", "alternativeName", "alternativeCode", "defaults"],
  data() {
    return {
      selectedCountry: null,
      provinces: null,
      selectedProvince: null,
      counties: null,
      selectedCounty: null,
      districts: null,
      selectedDistrict: null,
      specialCode: null,
      days: [],
      ubication: "",
      zona: "",
      selectedDay: "",
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.defaults) {
        this.selectedCountry = this.defaults.id_pais;
        this.loadProvinces();
        this.selectedProvince = this.defaults.id_provincia;
        this.loadCounty();
        this.selectedCounty = this.defaults.id_canton;
        this.loadDistricts();
        this.selectedDistrict = this.defaults.id_distrito;
        this.loadSpecialCode();
        this.ubication = this.defaults.ubicacion;
      }
    });
  },
  methods: {
    loadProvinces() {
      this.provinces = null;
      this.counties = null;
      this.districts = null;
      this.specialCode = null;
      this.selectedProvince = null;
      this.counties = null;
      this.days = [];
      this.selectedDay = null;
      this.zona = null;
      this.selectedCounty = null;
      this.selectedDistrict = null;
      this.provinces = this.locations.find(
        (x) => x.location_id == this.selectedCountry
      ).childs;
    },
    loadCounty() {
      this.districts = null;
      this.specialCode = null;
      this.days = [];
      this.selectedDay = null;
      this.zona = null;
      this.selectedDistrict = null;
      this.counties = this.provinces.find(
        (x) => x.location_id == this.selectedProvince
      ).childs;
    },
    loadDistricts() {
      this.districts = null;
      this.specialCode = null;
      this.districts = this.counties.find(
        (x) => x.location_id == this.selectedCounty
      ).childs.filter(({ location_category_id }) => location_category_id === 'Distrito');
    },

    loadSpecialCode() {
      this.specialCode = this.districts.find(
        (x) => x.location_id == this.selectedDistrict
      ).special_code;

      axios
        .get("/app/asm/getzona", { params: { specialCode: this.specialCode } })

        .then((response) => {
          this.zona = response.data;
        })
        .catch((err) => {
          console.log("Error: " + err);
        });

      axios
        .get("/app/asm/getdata", { params: { specialCode: this.specialCode } })
        .then((response) => {
          this.days = response.data;
        })
        .catch((err) => {
          console.log("Error: " + err);
        });
    },
  },
};
</script>
