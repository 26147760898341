<template>
    <section>
        <b-button type="is-blue" icon-pack="far" icon-left="edit" @click="checkStatus">Gestionar Producto</b-button>
    </section>
</template>

<script>

const SaveEntry = {
    name: "RegisterProductEntry",
    props: ['incidents', 'currentIncident', 'hasMf', 'documents'],
    data() {
        return {
            result: null,
            incident: null,
            comment: null,
            selectedDocuments: [],
            deliveryType: false,
        }
    },
    mounted() {
    },
    template:  `<form action="">
                    <div class="modal-card" style="width: auto">
                        <header class="modal-card-head">
                            <p class="modal-card-title">Registrar bitácora</p>
                        </header>
                        <section class="modal-card-body">
                            <p :class=" [ 'is-size-4',
                                            { 'has-text-success': currentIncident == 'Finalizado' },
                                            { 'has-text-warning': currentIncident == 'Pendiente de finalizar' }
                                        ]">{{ currentIncident }}</p>
                            <b-field label="Producto trabajado fuera de Istmo Center:">
                                <div class="block">
                                    <b-radio v-model="result" native-value="finalizado" name="estado_bitacora" type="is-success">Finalizado</b-radio>
                                    <b-radio v-model="result" native-value="pendiente" name="estado_bitacora" type="is-warning">Pendiente de finalizar</b-radio>
                                    <b-radio v-model="result" v-show="hasMf" native-value="fallado" name="estado_bitacora" type="is-danger">Error en formalización</b-radio>
                                </div>
                            </b-field>
                            <template v-if="result == 'finalizado'">
                                <b-field label="Tipo de entrega">
                                    <b-select name="tipo_entrega" v-model="deliveryType">
                                        <option :value="false">Entrega a TH</option>
                                        <option :value="true">Entrega en agencia</option>
                                    </b-select>
                                </b-field>
                            </template>
                            <template v-if="result == 'fallado'">
                                Documentos a recuperar (marque los documentos incorrectos):
                                <div style="width: 30%;">
                                    <b-field v-for="document in documents" :key="document.id_documento">
                                        <b-checkbox v-model="selectedDocuments" :native-value="document.id_documento">
                                            {{ document.descripcion_documento_cliente }}
                                        </b-checkbox>
                                    </b-field>
                                </div>
                                <b-field label="Tipo de incidente:" label-position="on-border" for="tipo_incidente">
                                    <b-select name="tipo_incidente" v-model="incident" icon="times-circle" placeholder="Seleccione un incidente">
                                        <option v-for="incidentOption in incidents" :value="incidentOption.id_incidente" :key="incidentOption.id_incidente">
                                            {{ incidentOption.tipo_incidente }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field label="Comentario:" for="comentario" label-position="on-border">
                                    <b-input name="comentario" type="textarea" v-model="comment"></b-input>
                                </b-field>
                            </template>
                        </section>
                        <footer class="modal-card-foot">
                            <b-button @click="$parent.close()">Cancelar</b-button>
                            <span class="field has-margin-left-10">
                                <span class="control">
                                    <button class="button is-primary" type="submit" v-on:click.stop.prevent="registryEntry">Guardar</button>
                                </span>
                            </span>
                        </footer>
                    </div>
                </form>`,
    methods: {
        registryEntry()
        {
            if (this.result == 'fallado' && this.incident == null)
            {
                this.$buefy.dialog.alert({
                        title: 'Sin incidente seleccionado',
                        message: 'Por favor seleccione un tipo de incidente',
                        confirmText: 'Cerrar',
                        canCancel: ['escape', 'outside']
                    })
                return;
            }

            if (this.result == 'fallado' && this.selectedDocuments.length == 0)
            {
                this.$buefy.dialog.alert({
                        title: 'Sin documentos seleccionados',
                        message: 'Por favor seleccione los documentos a recuperar',
                        confirmText: 'Cerrar',
                        canCancel: ['escape', 'outside']
                    })
                return;
            }

            if (this.currentIncident.length > 0)
            {
                this.$parent.$options.parent.updateRecord(this.result, this.incident, this.comment, this.selectedDocuments, this.deliveryType)
            }
            else
            {
                this.$parent.$options.parent.saveRecord(this.result, this.incident, this.comment, this.selectedDocuments, this.deliveryType)
            }
            this.$parent.close()
        },
    },
    watch: {
        'result': function(newVal, oldVal) {
            if (newVal != 'fallado')
            {
                this.incident = null
                this.comment = ''
            }
        }
    }
}

const ResolverConflicto = {
    props: ['mistakenEntry'],
    data() {
        return {
            resolution: false,
            resolutionType: null
        }
    },
    mounted() {
    },
    template:  `<form action="">
                    <div class="modal-card" style="width: auto">
                        <header class="modal-card-head">
                            <p class="modal-card-title">Registrar bitácora</p>
                        </header>
                        <section class="modal-card-body">
                            <p class="has-text-danger is-size-4">Mala formalización</p>
                            <p>Tipo de incidente: <strong class="has-text-primary">{{ mistakenEntry.incident.tipo_incidente }}</strong></p>
                            <p>Comentario: <strong class="has-text-primary">{{ mistakenEntry.comentario }}</strong></p>
                            <p>Corregido: <span class="has-text-danger is-size-5 has-text-weight-semibold">No</span></p>
                            <b-field>
                                <b-checkbox size="is-medium" name="corregido" v-model="resolution">Corregido</b-checkbox>
                            </b-field>
                            <b-field label="Tipo correción:" label-position="on-border" for="tipo_correccion">
                                <b-select name="tipo_correcion" v-model="resolutionType" placeholder="Seleccione un valor" :disabled="!resolution">
                                    <option value="Corrección con correo de ISTMO">Corrección con correo de ISTMO</option>
                                    <option value="Corrección con Digital por ISTMO">Corrección con Digital por ISTMO</option>
                                    <option value="Corrección con Doc Físico">Corrección con Doc Físico</option>
                                     <option value="Producto  corregido por Formalizador">Producto  corregido por Formalizador</option>
                                </b-select>
                            </b-field>
                        </section>
                        <footer class="modal-card-foot">
                            <b-button @click="$parent.close()">Cancelar</b-button>
                            <span class="field has-margin-left-10">
                                <span class="control">
                                    <button class="button is-primary" type="submit" v-on:click.stop.prevent="updateStatus">Actualizar</button>
                                </span>
                            </span>
                        </footer>
                    </div>
                </form>`,
    methods: {
        updateStatus()
        {
            if(this.resolution)
            {
                this.$parent.$options.parent.fixMistakenStatus(this.resolution, this.resolutionType)
            }
            this.$parent.close();
        }
    },
    watch: {
        'resolution': function(newVal, oldVal) {
            if (!newVal)
            {
                this.resolutionType = null
            }
        }
    }
}

    export default {
        name: 'RegisterProductEntry',
        props: {
            idproducto: Number,
            page: String,
            api: String,
            refreshWhenSubmit: {
                type: Boolean,
                default: true,
            },
            hasMf: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                selectedResult: '',
                documentList: [],
            }
        },
        mounted() {
            axios
                .post(this.api + '/documentos', { id_producto: this.idproducto})
                .then(response => {
                    this.documentList = response.data
                })
        },
        methods: {
            checkStatus()
            {
                axios
                    .post(this.api, { id_producto: this.idproducto })
                    .then(response => {
                        if (Object.entries(response.data).length === 0 && response.data.constructor === Object) {
                            this.createRecord()
                        }
                        else {
                            this.showInfo(response.data)
                        }
                    })
            },
            createRecord(status = '')
            {
                axios
                    .post(this.api + '/incidentes')
                    .then(response => {
                        this.$buefy.modal.open({
                            parent: this,
                            component: SaveEntry,
                            hasModalCard: true,
                            props: { incidents: response.data, currentIncident: status, hasMf: this.hasMf, documents: this.documentList }
                        })
                    })
            },
            showInfo(bitacora)
            {
                var title = "Estado externo"
                var message = ``
                var showMessage = false

                if (bitacora.trabajado && !bitacora.trabajado_sin_finalizar)
                {
                    message = `Finalizado`
                    if (bitacora.entregado_agencia)
                    {
                        message = `Finalizado en agencia`
                    }
                    this.createRecord(message)
                    return
                }

                else if (bitacora.trabajado_sin_finalizar)
                {
                    message = `Pendiente de finalizar`
                    this.createRecord(message)
                    return
                }

                if (bitacora.mala_formalizacion)
                {
                    if (bitacora.correccion_revisada)
                    {
                        message = `<p class="has-text-danger is-size-4">Mala formalización</p>
                                    <p>Tipo de incidente: <strong class="has-text-primary">${(bitacora.incident ? bitacora.incident.tipo_incidente : 'No registrado' )}</strong></p>
                                    <p>Comentario: <strong class="has-text-primary">${bitacora.comentario}</strong></p>
                                    <p>Corregido: ${bitacora.correccion_revisada ? `<span class="has-text-success is-size-5 has-text-weight-semibold">Si</span>` : `<span class="has-text-danger is-size-5 has-text-weight-semibold">No</span>`}</p>
                                    <p>Tipo correción: ${bitacora.tipo_correccion}</p>`
                        showMessage = true
                    }
                    else
                    {
                        showMessage = false
                        this.resolveEntry(bitacora)
                        return
                    }
                }

                if (showMessage)
                {
                    this.$buefy.dialog.alert({
                        title: title,
                        message: message,
                        confirmText: 'Cerrar',
                        canCancel: ['escape', 'outside']
                    })
                }
                else
                {
                    this.createRecord()
                }
            },
            saveRecord(result, incident, comment, documents, deliveryType)
            {
                this.selectedResult = result
                axios
                    .post(this.api + '/guardar', { id_producto: this.idproducto, result: result, incident: incident, comment: comment, documents: documents, delivery_type: deliveryType })
                    .then(() => {
                        this.$buefy.dialog.alert({
                            title: 'Entrada registrada',
                            message: 'Se guardó la entrada de la bitácora',
                            confirmText: 'Aceptar',
                            canCancel: ['escape', 'outside'],
                            onConfirm: () => { this.reloadPage() },
                            onCancel: () => { this.reloadPage() }
                        })
                    })
            },
            updateRecord(result, incident, comment, documents, deliveryType)
            {
                this.selectedResult = result
                axios
                    .put(this.api + '/actualizar', { id_producto: this.idproducto, result: result, incident: incident, comment: comment, documents: documents, delivery_type: deliveryType })
                    .then(() => {
                        this.$buefy.dialog.alert({
                            title: 'Entrada registrada',
                            message: 'Se guardó la entrada de la bitácora',
                            confirmText: 'Aceptar',
                            canCancel: ['escape', 'outside'],
                            onConfirm: () => { this.reloadPage() },
                            onCancel: () => { this.reloadPage() }
                        })
                    })
            },
            resolveEntry(bitacora)
            {
                this.$buefy.modal.open({
                    parent: this,
                    component: ResolverConflicto,
                    hasModalCard: true,
                    props: { mistakenEntry: bitacora }
                })
            },
            fixMistakenStatus(resolution, resolutionType)
            {
                axios
                    .patch(this.api + '/arreglar', { id_producto: this.idproducto, resolution: resolution, resolutionType: resolutionType })
                    .then(() => {
                        this.$buefy.dialog.alert({
                            title: 'Entrada actualizada',
                            message: 'Se actualizó el registro',
                            confirmText: 'Aceptar',
                            canCancel: ['escape', 'outside'],
                            onConfirm: () => { this.toCompletedProducts() },
                            onCancel: () => { this.toCompletedProducts() }
                        })
                    })
            },
            reloadPage()
            {
                if (this.refreshWhenSubmit)
                    if (this.selectedResult !== 'fallado')
                        window.location.href = window.location.href
                    else
                        this.toCompletedProducts()
                else
                    this.$emit('reload-view')
            },
            toCompletedProducts()
            {
                document.location.href = '/app/asm/asm_tracking_descargas_cliente_producto_entregado'
            }
        }
    }
</script>
